<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-24 09:51:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-08 13:56:40
-->
<template>
  <div class=''>
    <a-modal :maskClosable="false" title="操作" style="top: 8px;" width="85%" v-model="visible" :footer="null"
      :confirmLoading="isLoading" @cancel="toCancel">
      <a-form-model ref="form" :model="rowData" :labelCol="{span: 6}" :wrapperCol="{span:18}">
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="订单编号">
              <a-input v-model.trim="rowData.orderCode" placeholder="订单编号" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="期望到货时间">
              <DatePicker :startTime.sync="rowData.expectTime" disabled></DatePicker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="联系方式">
              <a-input v-model.trim="rowData.customerContact" placeholder="联系方式" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="联系人">
              <a-input v-model.trim="rowData.customerName" placeholder="联系人" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="地址">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="rowData.province"
                :city.sync="rowData.city"
                :district.sync="rowData.areaName"
                :provinceId.sync="rowData.provinceId"
                :cityId.sync="rowData.cityId"
                :districtId.sync="rowData.areaId"
                >
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="详细地址">
              <a-input v-model.trim="rowData.address" placeholder="详细地址" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-col :md="24">
          <a-form-model-item label="订单备注" :labelCol="{span: 3}" :wrapperCol="{span:21}">
            <a-textarea placeholder="订单备注" v-model.trim="rowData.orderRemark" disabled></a-textarea>
          </a-form-model-item>
        </a-col>
        <!-- <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="快递公司类型" :rules="[{required:true,message:'快递公司类型',trigger:'blur'}]">
              <a-select placeholder="请选择" @change="onChange">
                <a-select-option :value="item.id" v-for="(item, index) in LogisticsType" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="请选择物流公司" :rules="[{required:true,message:'请选择物流公司',trigger:'blur'}]">
              <a-select placeholder="请选择" label-in-value @change="onBrandChange">
                <a-select-option :value="item.id" v-for="(item, index) in deliverys" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="快递单号" :rules="[{required:true,message:'请填写快递单号',trigger:'blur'}]">
              <a-input v-model.trim="rowData.deliveryNum" placeholder="请填写快递单号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="物流信息" prop="logisticsRemark" v-show="showLogisticsRemark">
              <a-input v-model.trim="rowData.logisticsRemark" placeholder="物流信息" :max-length="100"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="附件：" prop="(logisticsPicture" v-show="showLogisticsRemark">
              <QiniuUpload type="image" :value.sync="rowData.logisticsPicture"></QiniuUpload>
            </a-form-model-item>
          </a-col>
        </a-row> -->

        <a-table :data-source="tableData" :rowKey="record => record.id" :pagination="false" bordered>
          <a-table-column title="商品图片" data-index="proPicture" align="center">
            <template slot-scope="text">
              <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
            </template>
          </a-table-column>
          <a-table-column title="商品名称" data-index="proName" align="center"></a-table-column>
          <a-table-column title="分类" data-index="proOrgCodeName" align="center"></a-table-column>
          <a-table-column title="规格" data-index="specValue" align="center"></a-table-column>
          <a-table-column title="品牌" data-index="proBrandName" align="center"></a-table-column>
          <a-table-column title="建议零售价" data-index="proPrice" align="center">
            <template slot-scope="text">{{ text.toFixed(2) }}</template>
          </a-table-column>
          <a-table-column title="购买数量" data-index="purchaseNum" align="center"></a-table-column>
          <a-table-column title="数量" data-index="" align="center" :width="120">
            <template slot-scope="text, row">
              <a-input-number v-model="row.shipNum" :min="1"></a-input-number>
            </template>
          </a-table-column>
          <!-- <a-table-column title="单位" data-index="proUnit" align="center" :width="150"></a-table-column> -->
        </a-table>

        <div style="text-align: right;margin-top: 14px;">
          <a-button type="default" @click="toCancel">取消</a-button>
          <a-button type="primary" @click="toSubmit">确认</a-button>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { selectByIdOrderShipInfo } from '../api/OrderInfoApi'

export default {
  name: '',
  data() {
    return {
      showLogisticsRemark: true,
      handle: '',
      isLoading: false,
      visible: false,
      rowData: {},
      deliverys: {},
      tableData: [],
      LogisticsType: [
        {
          id: 1,
          name: '自主查询'
        },
        {
          id: 2,
          name: '手动查询'
        }
      ]
    }
  },

  computed: {},
  created() {

  },
  methods: {
    setRowData(row, handle) {
      this.visible = true
      this.handle = handle
      if (handle === 'generate') {
        selectByIdOrderShipInfo(row.id).then(res => {
          this.rowData = res.body
          this.tableData = res.body.orderInfoDetailVOList.map(e => {
            return {
              shipNum: 1,
              ...e
            }
          })
          this.$nextTick(() => {
            this.$refs.pcd.setPCD()
          })
        })
      }
    },

    toCancel() {
      this.visible = false
    },

    onBrandChange(val) {
      this.rowData.deliveryId = val.key
      this.rowData.deliveryName = val.label
    },

    onChange(val) {
      if (val === 1) {
        this.showLogisticsRemark = false
      } else {
        this.showLogisticsRemark = true
      }
      this.axios.get('/api/order/logistics/logisticsCompany/list', {params: {queryType: val}}).then(res => {
        this.deliverys = res.body.records
      })
    },

    // 提交
    toSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.rowData.orderInfoDetailVOList = this.tableData
          this.isLoading = true
          this.axios.post(`/api/order/system/orderShipInfo/save`, this.rowData).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.visible = false
              this.$emit('reload')
              this.$bus.$emit('getList')
            }
          }).catch(err => {
          }).finally(() => {
            this.isLoading = false
          })
        }
      })

    },
  }
}
</script>

<style lang='scss' scoped>
</style>